import React from "react"
import { render } from "react-dom"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import loadable from "@loadable/component"
import reportWebVitals from "./reportWebVitals"
import "./index.scss"

const Home = loadable(() => import("./views/home/Home"))
const Search = loadable(() => import("./views/search/Search"))
const WrapperI18n = loadable(() => import("./WrapperI18n"))

const rootElement = document.getElementById("root")

render(
  <WrapperI18n>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </BrowserRouter>
  </WrapperI18n>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
